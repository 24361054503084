.custom_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.custom_input > * {
    margin-outside: .5rem !important;
}


.text_green {
    color: #124057 !important;
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif !important;
}

.input_error {
    border: 1px solid red !important;
}

.form-label {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
}

.label_error {
    color: red !important;
}

.form-control {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
}

.form-control-sm {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
}

.user_profile_text_green {
    color: #efefef !important;
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif !important;
    font-size: 1rem !important;
    font-weight: 600;
    line-height: 1.5;
}

.custom_font {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
    line-height: 1.5;
    font-weight: 600;
}

.font_helvetica {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif !important;
    color: #161c2d !important;
}


.updates-title {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
    font-weight: bold;
    color: #212529 !important;
    font-size: 1rem !important;
}


.custom-border-box img :hover {
    transform: translateY(-3px);
    transition: all .3s ease;
    box-sizing: border-box;
}

.cursor_pointer {
    cursor: pointer;
}

.creation-date {
    font-size: 14px !important;
}

.custom_toast svg {
    color: #efefef !important;
}

.loader-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.number-li {
    color: #7c7c7c;
    font-size: 6.5rem;
    line-height: .8461538462;
    opacity: .4;
    font-weight: 700;
}

.border-button {
    border-radius: 5px !important;
}

.font-700 {
    font-weight: 700;
}

.font-400 {
    font-weight: 400 !important;
}

.layout-wrapper {
    min-height: 100vh; /* will cover the 100% of viewport */
    display: block;
    position: relative;
    padding-bottom: 5rem; /* height of your footer */
}

.custom-simple-bar {
    max-height: 400px;
    box-shadow: 0 1px 2px 2px lightgrey;
    padding: 1.5rem .3rem;
    border-radius: 5px;
    font-size: 14px !important;
}

#simple-bar {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
    text-align: left;
    font-size: 1rem;
}

.simple-bar-text-bold {
    font-weight: 700;
    font-size: 14px;
}

#privacy-policy {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    font-size: 1rem;
    text-align: left;
}

#privacy-policy ul li {
    margin-left: 2rem;
}

.bold {
    font-weight: bold;
    font-size: 1.5rem;
}

.text-bold {
    font-weight: bold;
}

.custom_ol {
    font-size: 1.4rem;
    font-weight: 500;
}

#revycare {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif !important;
}

#condizioni {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif !important;
    display: block;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1rem;
}

#protezione {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif !important;
    display: block;
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 1rem;
}

#crediti {
    font-family: "Helvetica Neue LT W06 45 Light", sans-serif !important;
    display: block;
    margin-left: 3rem;
    margin-right: 3rem;
    font-size: 1rem;
}

.font-12 {
    font-size: 12px;
}
.text-underline {
    text-decoration: underline;
}
.margin-bottom {
    margin-bottom: 2px !important;
}

.border-box {
    border: 1px solid black;
    padding: .5rem;
}

@media (max-width: 600px) {
    .max_width_input {
        max-width: 12rem !important;
    }
}

.section_three{
    padding-top: 40px;
    /* background-color: azure; */
}

.card {
    border-radius: 4px;
    background-color: #fff;
    margin-bottom: 30px
}

/* .card .card-image {
    width: 100%;
    overflow: hidden;
    height: 260px;
    border-radius: 4px 4px 0 0;
    position: relative;
    transform-style: preserve-3d
}

.card .card-image img {
    width: 100%
} */

/* .card .filter {
    position: absolute;
    z-index: 2;
    background-color: rgba(0,0,0,.68);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    filter: alpha(opacity=0)
}

.card .filter .btn {
    position: relative;
    top: 50%;
    transform: translateY(-50%)
} */
/* 
.card:hover .filter {
    opacity: 1;
    filter: alpha(opacity=100)
} */

/* .card .btn-hover {
    opacity: 0;
    filter: alpha(opacity=0)
} */

/* .card:hover .btn-hover {
    opacity: 1;
    filter: alpha(opacity=100)
}

.card .card-body {
    padding: 15px 15px 10px
} */

.card .card-header {
    padding: 15px 15px 0;
    background-color: #fff;
    border-bottom: none!important
}

/* .card .card-category,.card label {
    font-size: 18px;
    font-weight: 600;
    color: #124057;
    margin-bottom: 0
    
}

.card .card-category i,.card label i {
    font-size: 16px
}

.card label {
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase
} */

.card .card-title {
    margin: 0;
    color: #403f3f;
    font-weight: 500
}

/* .card .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px
} */

.card .description {
    font-size: 14px;
    color: #333
}

.card .card-footer {
    padding-top: 0;
    background-color: transparent;
    line-height: 30px;
    border-top: none!important;
    font-size: 14px
}

.card .card-footer .legend {
    padding: 5px 0
}

.card .card-footer hr {
    margin-top: 5px;
    margin-bottom: 5px
}

.card .stats {
    color: #a9a9a9
}

.card .card-footer div {
    display: inline-block
}

.card .author {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase
}
/* 
.card .author i {
    font-size: 14px
} */

.card h6 {
    font-size: 12px;
    margin: 0
}

.card.card-separator:after {
    height: 100%;
    right: -15px;
    top: 0;
    width: 1px;
    background-color: #ddd;
    card-body: "";
    position: absolute
}
/* 
.card .ct-chart {
    margin: 30px 0;
    height: 245px
}

.card .ct-label {
    font-size: 1rem!important
}

.card .table tbody td:first-child,.card .table thead th:first-child {
    padding-left: 15px
}

.card .table tbody td:last-child,.card .table thead th:last-child {
    padding-right: 15px
}

.card .alert {
    border-radius: 4px;
    position: relative
}

.card .alert.alert-with-icon {
    padding-left: 65px
}

.card-stats .card-body {
    padding: 15px 15px 0
}

.card-stats .card-body .numbers {
    font-size: 1.8rem;
    text-align: right
}

.card-stats .card-body .numbers p {
    margin-bottom: 0
}

.card-stats .card-footer {
    padding: 0 15px 10px
} */

/* .card-stats .icon-big {
    font-size: 3em;
    min-height: 64px
}

.card-stats .icon-big i {
    font-weight: 700;
    line-height: 59px
}

.card-user .card-image {
    height: 110px
}

.card-user .card-image-plain {
    height: 0;
    margin-top: 110px
} */
/* 
.card-user .author {
    text-align: center;
    text-transform: none;
    margin-top: -70px
} */

/* .card-user .avatar {
    width: 124px;
    height: 124px;
    border: 5px solid #fff;
    position: relative;
    margin-bottom: 15px
} */

/* .card-user .avatar.border-gray {
    border-color: #eee
} */

/* .card-user .title {
    line-height: 24px
} */

.card-user .card-body {
    min-height: 240px
}

.card-price .card-footer,.card-user .card-footer {
    padding: 5px 15px 10px
}

.card-price hr,.card-user hr {
    margin: 5px 15px
}

.card-plain {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0
}

.card-plain .card-image {
    border-radius: 4px
}

.card.card-plain {
    border: none!important
}

.card.card-plain .card-header {
    background-color: transparent!important
}

.hdg-label-popup {
    display: none;
    position: absolute
  }
  
  .icon:hover~.hdg-label-popup {
    display: block
  }

.input-group-text{
    height: 40px;
    cursor: pointer;
}




.DateContainer {
    display: flex;
    align-items: center;
    width: 50vw;
    height: 25px;
    border: 1px solid black;
    border-radius: 8px;
    /* background-color: white; -->set this background color to whatever backgroundcolor you want the picker to be  */
  }
  
  .dateInput {
    width: 100%;
    height: 100%;
  }
  
  .dateInput input {
    width: calc(100% + 25px);
    height: 25px;
    border: none;
    background: transparent;
    z-index: 1;
  }
  
  .icon {
    width: 30px;
  }
  
  .icon svg {
    margin-top: 3px;
  }
  
  textarea:focus,
  input:focus {
    outline: none;
  }
  
  .datepicker-icon{
    float: right;
    /* width: 200px; */
    height: 40px;
  }

  .accordion{
    /* background-color: #efefef; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.3rem;
  }

  .filters {
   border-radius: 0.3rem;
    border-radius: 1px solid #fefe;
  }